@import 'imports.scss';
@import 'variables.scss';
@import 'mixins.scss';
@import 'reinitialize.scss';
#width-tester{position: fixed; background: white; z-index: 999;right: 0;}



#width-tester{position: fixed; background: white; z-index: 999;right: 0;}

/*

0 - GENERAL SETTINGS
1 - GRIDS
2 - MENU NAVIGATION
3 - SLIDER 
	3.1 Key numbers
	3.2 Red theme
	3.3 Blue theme
3B - SLIDE ALONE
4 - LAYER TEXT
5 - BUSINESS SECTOR

6 - CONTACT
7 - SMALL COMPONENTS
	7.1 - scroll button
	7.2 - List render
	7.3 - FORMS STYLISH
		7.3.1 - Text input
		7.3.2 - Textarea
		7.3.3 - Buttons
		7.3.4 - error messages 
	7.4 - Loader
	7.5 - Footer
	7.6 - Pop-up
	7.7 - tarteaucitron
	7.8 - PopUp component
8 - ANIMATIONS FULL PAGE

*/

// ==================================================
// 0 - GENERAL SETTINGS
// ==================================================
html, body {
	scroll-behavior: smooth;
	-webkit-font-smoothing: subpixel-antialiased;
}
.page-container{
	opacity: 0;
	@include media-min("phonemax"){
		&:before {
			content: '';
			position: fixed;
			pointer-events: none;
			border: 2px dotted rgba(012, 012, 012, 0.15);
			height: 100%;
			width: calc(100% - 203px);
			left: 100px;
			z-index: 60;
			top: 0;
		}
	}
}

.full-page{
	@include media-min-max("phone", "tabletmax"){
		width: 100vw;
		height: auto;
		padding-top: $spacefixsmall;
		padding-bottom: $spacefixsmall;
	}
	@include media-min("laptop"){
		width: 100vw;
		height: 100vh;
		padding-top: 80px;
		padding-bottom: 0;
	}
}
h1, h2{
	@include setfont(700, 36px, 42px);
}
h3{
	@include setfont(700, 20px, 22px);
}
.bordered-bott,
.bordered_bott{
	position: relative;
	padding-bottom: $spacefixsmall;
	padding-top: $spacefixsmall;
	margin-bottom: $spacefixsmall;
	&:before{
		content:'';
		position: absolute;
		width: 60px;
		height: 5px;
		background: $reddarker;
		bottom: -2.5px;
	}
}
.subtitle{
	color: $black;
	@include setfont(700, 20px, 30px);
}
.text{
	color: $greydarker;
	@include setfont(400, 16px, 24px);
	p{margin-bottom: $halfspacefixsmall;}
}
.picto{
	width: $pictowidth;
	height: $pictowidth;
}


// ==================================================
// 1 - GRIDS
// ==================================================

.main_grid_pad{
	@include media-min('phone'){
		padding-left: $spacefixsmall; padding-right: $spacefixsmall;
	}
	@include media-min("tablet"){
		padding-left: $spacefixsmall; padding-right: $spacefixsmall;
	}
	@include media-min('laptop'){
		padding-left: $spacefixlarge; padding-right: $spacefixlarge;
	}
}
.main_grid_pad_left{
	@include media-min('phone'){
		padding-left: $spacefixsmall;
	}
	@include media-min("tablet"){
		padding-left: $spacefixsmall;
	}
	@include media-min('laptop'){
		padding-left: $spacefixlarge;
	}
 }
.main_grid_pad_right{
	@include media-min('phone'){
		padding-right: $spacefixsmall;
	}
	@include media-min("tablet"){
		padding-right: $spacefixsmall;
	}
	@include media-min('laptop'){
		padding-right: $spacefixlarge;
	}
}

.main_grid_mar{
	@include media-min('phone'){
		margin-left: $spacefixsmall; margin-right: $spacefixsmall;
	}
	@include media-min("tablet"){
		margin-left: $spacefixsmall; margin-right: $spacefixsmall;
	}
	@include media-min('laptop'){
		margin-left: $spacefixlarge; margin-right: $spacefixlarge;
	}
 }
.main_grid_mar_left{
	@include media-min("phone"){
		margin-left: $spacefixsmall;
	}
	@include media-min("tablet"){
		margin-left: $spacefixsmall;
	}
	@include media-min("laptop"){
		margin-left: $spacefixlarge;
	}
}
.main_grid_mar_right{
	@include media-min("phone"){
		margin-right: $spacefixsmall;
	}
	@include media-min("tablet"){
		margin-right: $spacefixsmall;
	}
	@include media-min("laptop"){
		margin-right: $spacefixlarge;
	}
}
.main_grid_mar_bott{
	@include media-min("phone"){
		margin-bottom: $spacefixsmall;
	}
	@include media-min("tablet"){
		margin-bottom: $spacefixsmall;
	}
	@include media-min("laptop"){
		margin-bottom: $spacefixlarge;
	}
}
.main_grid_mar_top{
	@include media-min("phone"){

	}
	@include media-min("tablet"){
	}
	@include media-min("laptop"){
		margin-top: $spacefixlarge;
	}
}


.half_small_grid_pad{ padding-left: $halfspacefixsmall; padding-right: $halfspacefixsmall; }

.small_grid_pad{ padding-left: $spacefixsmall; padding-right: $spacefixsmall; }
.small_grid_pad_left{ padding-left: $spacefixsmall; }
.small_grid_pad_right{ padding-right: $spacefixsmall; }

.small_grid_mar{ margin-left: $spacefixsmall; margin-right: $spacefixsmall; }
.small_grid_mar_left{ margin-left: $spacefixsmall; }
.small_grid_mar_right{ margin-right: $spacefixsmall; }
.small_grid_mar_bott{ margin-bottom: $spacefixsmall; }
.small_grid_mar_top{ margin-top: $spacefixsmall; }


.flex-row{
	display: flex;
	flex-wrap: nowrap;
	.col{
		flex: 1;
	}
}



// ==================================================
// 2 - MENU NAVIGATION
// ==================================================
.classic_page{
	#menu-nav{
		top: 81px;
		.btn-classic{text-decoration: none;}
	}
}
#menu-nav {
	position: fixed;
	transform: translateY(-100%);
	width: 100vw;
	z-index: 79;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: nowrap;
	max-height: 80px;
	text-transform: uppercase;
	background: $whitelighter;
	@include setfont(700, 13px, 20px);
	@include easetransition;
	&.top-fixed{
		background: transparent; @include easetransition;
	}
	.logo-container{
		max-height: 100px;
		.logo{
			height: 100px;
			width: 175px;
			.logo{
				transition: 0.3s ease-out;
				transform-origin: 0% 0%;
				transform: scale(1);
			}
		}
	}
	.links-container{
		padding-left: $spacefixsmall;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		.link{
			color: $black;
			text-decoration: none;
			text-transform: uppercase;
			padding: 0 $halfspacefixsmall;
			cursor: pointer;
			position: relative;
			@include scaleY(1, 50%);
			@include easetransition;
			@include media-max('laptop'){
				display: none;
			}
			&:before{
				content: '';
				position: absolute;
				width: 0%;
				height: 3px;
				background: $reddarker;
				bottom: -10px;
				left: auto;
				right: 15px;
				@include easetransition;
			}
			&:hover, &.active {
				&:before{
					width: calc(100% - 30px);
					left: 15px;
					right: auto;
					@include easetransition;
				}
			}
		}
		.btn-classic{
			color: $whitelighter;
			text-decoration: none;
			font-weight: 700;
			text-transform: uppercase;
		}
	}
}


// ==================================================
// 3 - SLIDER COMPONENT
// ==================================================

.slider{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	position: relative;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	z-index:  69;
	.swipe_invitation{
		position: absolute;
		width: 100vw;
		height: 100%;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		z-index: 99;
		background: linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,0.95));
		.swipe_invitation.active{
			animation: animSwipper .5s;
		}
		.picto{
			width: 60px;
			height: 60px;
			margin-right: $halfspacefixsmall;
			// animation: animSwipperPicto 3s;
			// animation-iteration-count: infinite;
			position: relative;
		}
	}
	.slide{
		position: absolute;
		height: 100vh;
		width: 100vw;
		top: 0px;
		display: flex;
		justify-content: flex-start;
		box-shadow: -20px 0px 90px 0px rgba(0,0,0,0.15);
		background-size: cover;
		user-select: none;
		@include media-min("laptop"){
			padding-top: 100px;
			padding-bottom: 150px;
		}
		&.visible{z-index: 9}
		&.not-visible{z-index: 2}
		
		.text-container{
			.mobile_text, 
			.text, 
			.key-number, 
			.title{
				pointer-events: none;
			}
			position: relative;
			z-index: 9;
			max-width: 75%;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none; 
			user-select: none;
			display: flex;
			flex-wrap: wrap;
			align-content: center;
			@include media-min-max("phone", "tabletmax"){
				min-width: calc(100%);
			}
			@include media-min("laptop"){
				min-width: calc(75% + 50px);
			}
			.title{
				color: #000000;
				position: relative;
				@include setfont(900 ,36px, 42px);
				&:first-letter{text-transform: uppercase;}
				@include media-max("laptop"){
					@include setfont(900 ,25px, 30px);
				}
			}
			.text{
				
				color: #000000;
				p{
					margin-bottom: $halfspacefixsmall;
					&:last-child{margin-bottom: 0;}
				}
				&:first-letter{text-transform: uppercase;}
				@include media-min("phone"){@include setfont(400, 16px, 20px);}
				@include media-min("laptop"){@include setfont(400, 20px, 30px);}
				&.scrollable-content{
					max-height: 340px;
					overflow: hidden;
					@include media-min-max("phone", "tabletmax"){
						overflow-y: scroll;
					}
				}
			}

			.mobile_text{
				max-height: 300px;
				position: relative;
				overflow: auto;
				.text{
					margin-bottom: $halfspacefixsmall;
					position: relative;
					z-index: 1;
				}
			}
			.btn-classic{
				position: relative;
				z-index: 4;
				@include setfont(700, 18px, 22px);
			}
		}
		// ==================================================
		// 3.1 - Key numbers slide

		&.keynumbers-slide{
			.text-container{
				.key-number{
					width: 100%;
					padding: $halfspacefixsmall 0;
					border-bottom: dashed 1px #ffffff;
					.title{
						@include setfont(700, 55px, 55px);
						color: $whitelighter;
						@include media-min-max("phone", "tabletmax"){
							@include setfont(700, 32px, 35px);
						}
					}
					.text{
						@include setfont(700, 20px, 30px);
						color: $whitelighter;
						padding-left: $halfspacefixsmall;
						@include media-min-max("phone", "tabletmax"){
							@include setfont(700, 18px, 22px);
						}
					}
				}
			}
		}
	}
	.slider-selector-container{
		display: inline-flex;
		align-self: flex-end;
		flex-wrap: nowrap;
		position: absolute;
		z-index: 199;
		bottom: 0;
		@include media-min-max("phone", "tabletmax"){
			width: 100%;
			margin: 0;
		}
		@include media-min('laptop'){
			width: calc(100% - 200px);
		}
		.slider-selector{
			display: flex;
			align-items: flex-start;
			flex-basis: 25%;
			color: $whitelighter;
			padding: $spacefixsmall;
			cursor: pointer;
			@include media-min-max("phone", "tabletmax"){
				height: 50px;
				padding: 0;
			}
			@include media-min("laptop"){
				height: 150px;
			}
			.slider-selector-label{
				@include setfont(700 ,20px, 26px);
				@include media-min-max("phone", "tabletmax"){
					display: none;
					@include setfont(700 ,15px, 20px);
				}
				@include media-min-max("laptop", "laptopmax"){
					@include setfont(700 ,15px, 20px);
				}
			}
			&:first-letter{text-transform: uppercase;}
			&:before{
				content:'';
				position: absolute;
				left: calc(50% - 60px);
				top: -58px;
				border-left: 60px solid transparent;
				border-right: 60px solid transparent;
				border-top: 30px solid transparent;
				align-self: center;
				@include easetransition;
				@include scaleY(0, 100%);
				@include media-min-max("phone", "tabletmax"){
					left: calc(50% - 20px);
					border-left: 20px solid transparent;
					border-right: 20px solid transparent;
					border-top: 20px solid transparent;
					top: -38px;
				}
			}
		}
	}

	// ==================================================
	// 3.2 Red theme

	&.theme-red{
		.slide{
			.title{
				color: $whitelighter;
				&:before{background: $whitelighter;}
			}
			.mobile_text{
				.text{
					background: -webkit-linear-gradient(#000000, transparent);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}
		}
		
		.slider-selector{
			background: $reddarker;
			@include easetransition;
			&:before{
				@include media-min-max("phone", "tabletmax"){
					border-bottom: 20px solid $redlighter;
				}
				@include media-min("laptop"){
					border-bottom: 30px solid $redlighter;
				}
			}
			&.active{
				background: $redlighter;
				@include easetransition;
				position: relative;
				&:before{
					@include scaleY(1, 100%);
					@include easetransition;
				}
			}
		}

		&.whited{
			.title{
				color: $whitelighter;
				&:before{background: $whitelighter;}
			}
			.text{
				color: $whitelighter;
			}
			.mobile_text{
				.text{
					background: -webkit-linear-gradient(#ffffff, transparent);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
				&:after{
					background: linear-gradient(180deg, transparent, #434B56 75%);
				}
			}
		}
	}


	// ==================================================
	// 3.3 Blue theme

	&.theme-blue{
		.slide{
			.title{
				color: $whitelighter;
				&:before{background: $whitelighter;}
			}
			.text{
				color: $whitelighter;
			}
			.mobile_text{
				.text{
					background: -webkit-linear-gradient(#ffffff, transparent);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
				&:after{
					background: linear-gradient(180deg, transparent, #434B56 75%);
				}
			}
		}
		.slider-selector{
			background: $bluedarker;
			@include easetransition;
			&:before{border-bottom: 30px solid $bluelighter;}
			&.active{
				background: $bluelighter;
				@include easetransition;
				position: relative;
				&:before{
					@include scaleY(1, 100%);
					@include easetransition;
				}
			}
		}
	}
}

// ==================================================
// 3B - SLIDE ALONE
// ==================================================

.slide_alone_component{
	position: relative;
	z-index: 69;
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	transition: 0.7s ease-in-out;
	left: 0px;
	opacity: 1;
	
	@include media-min-max("phone", "tabletmax"){
		padding: $spacefixsmall;
	}
	@include media-min("laptop"){
		padding: $spacefixlarge;
	}
	&>*{
		z-index:77;
	}
	.text_container{
		&.hidden{
			transition: 0.7s ease-in-out;
			opacity: 0;
			pointer-events: none;
		}
		max-width: 75%;
		.title{
			@include setfont(900 ,52px, 42px);
			&:first-letter{text-transform: uppercase;}
			@include media-max("laptop"){
				@include setfont(900 ,25px, 30px);
			}
		}
		.text{
			color: $black;
			@include media-min-max("phone", "tabletmax"){
				@include setfont(400, 16px, 20px);
				&>p>span{font-size: 18px !important;}
			}
			@include media-min("laptop"){
				@include setfont(700, 20px, 30px);
			}
			
		}
		.btn-classic{
			display: inline-flex;
			text-transform: uppercase;
			@include setfont(600, 16px, 20px);
			img{
				margin-left: 10px;

			}
		}
	}
	&.whited{
		.title{
			color: $whitelighter;
			&:before{background: $whitelighter;}
		}
		.text{
			color: $whitelighter;
			margin-bottom: $spacefixsmall;
		}
	}
}




// ==================================================
// 4 - LAYER TEXT
// ==================================================

.layer{
	&#who_we_are{
		@include media-max("laptop"){
			padding-left: 0px;
			padding-right: 0px;
			padding-bottom: 0px;
		}
		.banner{
			@include media-max("laptop"){
				width: calc(100% + 30px)
			}
		}
	}
	@include media-min("phone"){
		&.flex-row{
			flex-wrap: wrap;
		}
	}
	.main-content{
		display: flex;
		flex-basis: 75%;
		align-items: center;
		@include media-max("laptop"){
			flex-basis: 100%;
		}
		.subtitle{
			margin-bottom: $spacefixsmall;
		}
	}
	.banner{
		display: flex;
		flex-basis: 25%;
		align-items: center;
		color: $whitelighter;
		background: $reddarker;
		max-width: 25%;
		@include media-max("laptop"){
			max-width: 100%;
			padding-top: $spacefixsmall;
			padding-bottom: $spacefixsmall;
			margin-top: $spacefixsmall;
			flex-basis: 100%;
		}
		.text-container{
			width: 100%;
			.bordered-bott{
				&:before{background: $whitelighter;}
			}
			.banner_line{
				@include setfont(700, 20px, 25px);
				border-bottom: 1px dashed $whitelighter;
				padding: $halfspacefixsmall 0;
				margin-bottom: $halfspacefixsmall;
				.banner_subcontent{
					@include setfont(700, 13px, 22px);
					padding-top: $halfspacefixsmall;
				}
			}
		}
	}
}

// ==================================================
// 5 - BUSINESS SECTOR
// ==================================================
.business-sectors{
	display: flex;
	align-items: center;
	@include media-min-max("phone", 'tabletmax'){
		height: auto;
		padding-left: 0;
		padding-right: 0;
		&>.text-container>.flex-row{
			flex-wrap: wrap;
			.col{
				width: 100%;
				min-width: 235px;
				margin-bottom: $spacefixsmall;
			}
		}
	}
	@include media-min("laptop"){
		.text-container{
			width: 100%;
			&>.flex-row{
				justify-content: space-between;
			}
		}
	}
}

// ==================================================
// 6 - CONTACT PAGE
// ==================================================

.contact_section{
	@include media-min-max("phone","tabletmax"){
		padding-bottom: 0;
	}
	&>.flex-row:nth-child(1){
		height: calc(100% - 80px);
		flex-wrap: wrap;
		@include media-min-max("phone", "tabletmax"){
			flex-direction: column;
		}
	}
	&>.flex-row{
		&>.col:nth-child(1){
			display: flex;
			align-items: center;
			width: 100%;
			@include media-min-max("phone", 'phonemax'){
				padding-left: 0px;
			}
			.container{
				width: 100%;
				form{
					margin-top: $spacefixsmall;
					margin-bottom: $spacefixsmall;
					button[type="submit"]{
						margin-top: $spacefixsmall;
						@include setfont(700, 16px, 20px);
					}
				}
			}
		}
		&>.col:nth-child(2){
			@include media-min-max("phone", "tabletmax"){
				width: 100%;
			}
			@include media-min("laptop"){
				overflow: hidden;
			}
			.red_block{
				background: $reddarker;
				height: 33%;
				display: flex;
				align-items: center;
				@include media-max("tablet"){
					height: auto;
					padding: $halfspacefixsmall;
					margin-top: $spacefixsmall;
				}
				.business_name{
					color: $whitelighter;
					@include setfont(400, 20px, 22px);
					padding-bottom: $halfspacefixsmall;
				}
				.address,
				.phone,
				.fax{
					color: $whitelighter;
					padding-bottom: $halfspacefixsmall;
					text-decoration: none;
					@include setfont(400, 13px, 22px);
				}
				.phone,
				.fax{
					display: flex;
					align-items: center;
					.picto{
						margin-right: $halfspacefixsmall;
					}
				}
			}
			.contact_map{
				background: url('/images/carte-amitel.jpg');
				background-size: cover;
				background-position: 50% 50%;
				@include media-min("phone"){
					min-height: 400px;
				}
				@include media-min("laptop"){
					height: 67%;
				}
			}
		}
	}
}

// ==================================================
// 7 - SMALL COMPONENTS
// ==================================================

@keyframes scrollButtonAnimation{
	0%{}
	50%{}
	100%{}
}

// ==================================================
// 7.1 - scroll-button

.scroll-button{
	position: fixed;
	cursor: pointer;
	background: $whitelighter;
	bottom: 0;
	right: 0px;
	border: none;
	z-index: 199;
	padding: 0;
	opacity: 1;
	&.hidden{opacity: 0; z-index: 0;}
	@include easetransition;
	@include media-min("phone"){
		height: 50px;
		width: calc(25vw);

	}
	@include media-min("laptop"){
		height: 150px;
		width: $spacefixlarge;
	}
 	.arrow-bottom{
		width: 2px;
		position: absolute;
		background: $greydarker;
		height: calc(100% - 60px);
		left: calc(50% - 1px);
		top: 30px;
		&:before{
			content: '';
			position: absolute;
			bottom: 0;
			width: $halfspacefixsmall;
			height: $halfspacefixsmall;
			border-bottom: 2px solid $greydarker;
			border-left: 2px solid $greydarker;
			transform: rotateZ(-45deg);
			transform-origin: 0% 100%;
		}
	}
	&:hover{
		background: $whitedarker;
		@include easetransition;
	}
}

// ==================================================
// 7.2 - List render
.list_component{
	&>li{
		padding-left: $halfspacefixsmall + 5;
		position: relative;
		padding-bottom: $halfspacefixsmall;
		@include setfont(700, 13px, 22px);
		&:before{
			content: '';
			position: absolute;
			width: 8px;
			height: 8px;
			background: $greylighter;
			left: 0;
			top: 7px;
		}
		&>ul>li{
			@include setfont(400, 13px, 20px);
			padding-left: $halfspacefixsmall;
		}
	}
}



// ==================================================
// 7.3 - FORMS STYLISH



// 7.3.1 - Text input
form{
	width: 100%;
	.btn-classic{
		width: 100%;
		justify-content: center;
	}
}
.field-form{
	display: flex;
	flex-wrap: wrap;
	margin-bottom: $spacefixsmall;
	position: relative;
	&.active{
		label{
			color: $black;
			@include scaleAndTranslateY(0.8, -50px, 0%, 0%);
			@include easetransition;
		}
	}
	label{
		color: $greydarker;
		width: 100%;
		margin-bottom: $halfspacefixsmall;
		left: $halfspacefixsmall;
		top: $spacefixsmall + 2px;;
		position: absolute;
		pointer-events: none;
		@include setfont(400, 16px, 20px);
		@include scaleAndTranslateY(1, 0px, 0%, 0%);
		@include easetransition;
	}
	input{
		@include setfont(400, 16px, 20px);
		width: 100%;
		border: 1px solid $greylighter;
		padding: $halfspacefixsmall;
		margin-top: $halfspacefixsmall;
		@include easetransition;
		&::placeholder{ @include setfont(400, 16px, 20px); }

	}
	// 7.3.2 - Textarea
	textarea{
		@include setfont(400, 16px, 20px);
		width: 100%;
		border: 1px solid $greylighter;
		padding: $halfspacefixsmall;
		margin-top: $halfspacefixsmall;
		resize: vertical;
		&::placeholder{ @include setfont(400, 16px, 20px);}

	}
}

// 7.3.3 - Buttons
.btn-classic{
	background: $reddarker;
	color: $whitelighter;
	border: none;
	border-radius: 100px;
	height: 50px;
	display: flex;
	align-items: center;
	padding-left: $spacefixsmall;
	padding-right: $spacefixsmall;
	cursor: pointer;
	@include easetransition;
	&:hover{
		background: $redlighter;
		@include easetransition;
	}
	&.white{
		background: $whitelighter;
		color: $black;
		border: 2px solid $greydarker;
	}
}

// 7.3.4 - error messages 
.err_message{
	@include setfont(700, 13px, 20px);
	color: $reddarker;
	animation-name: error_message;
	animation-iteration-count: 1;
	animation-duration: 0.3s;
	position: relative;
}

@keyframes error_message{
	0%{	opacity: 0;	transform: translateX(0px);	}
	25%{opacity: 1;	transform: translateX(-5px);}
	50%{transform: translateX(10px);}
	75%{transform: translateX(-5px);}
	100%{transform: translateX(0px);}
}

// ==================================================
// 7.4 - Loader

@keyframes loadingLoader{
	0%{top:-30px; opacity: 0;}
	50%{top:0px; opacity: 1;}
	100%{top:-30px; opacity: 0;}
}

.loading{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	.fakelogo{
		@include setfontB(700, 50px, 60px);
		.letter{
			position: relative;
			opacity: 0;
			&:nth-child(1){	animation: loadingLoader 1s ease-in 0.1s infinite;}
			&:nth-child(2){	animation: loadingLoader 1s ease-in 0.2s infinite;}
			&:nth-child(3){	animation: loadingLoader 1s ease-in 0.3s infinite;}
			&:nth-child(4){	animation: loadingLoader 1s ease-in 0.4s infinite;}
			&:nth-child(5){	animation: loadingLoader 1s ease-in 0.5s infinite;}
			&:nth-child(6){	animation: loadingLoader 1s ease-in 0.6s infinite;}
		}
	}
}

// ==================================================
// 7.5 - Footer

footer{
	min-height: 80px;
	background: $reddarker;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	z-index: 99;
	@include setfont(400, 13px, 20px);
	color: $whitelighter;
	@include media-min-max("phone", 'tabletmax'){
		flex-direction: column
	}
	.links-container{
		@include media-min-max("phone", 'phonemax'){
			display: flex;
			flex-direction: column;
		}
		@include media-min("tablet"){
			display: flex;
			flex-direction: row;
		}
		.link{
			color: $whitelighter;
			text-decoration: none;
			padding: $halfspacefixsmall;
			cursor: pointer;
			position: relative;
			&:before{
				content: '';
				position: absolute;
				width: 0%;
				height: 3px;
				background: $whitelighter;
				bottom: 5px;
				left: auto;
				right: 15px;
				@include easetransition;
			}
			&:hover{
				&:before{
					position: absolute;
					width: calc(100% - 30px);
					left: 15px;
					right: auto;
					@include easetransition;
				}
			}
		}
	}
	.reference{
		padding: $halfspacefixsmall;
		color: $whitelighter;
		text-decoration: none;
	}
	&>.col:nth-child(1){
		@include media-min-max("phone", "tabletmax"){
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start !important;
		}
		@include media-min("laptop"){
			flex: 2;
		}
	}
	&>.col:nth-child(2){
		@include media-min("phone"){
			display: flex;
			justify-content: flex-start;
		}
		@include media-min("laptop"){
			display: flex;
			flex: 1;
			justify-content: flex-end;
		}

	}
}

// ==================================================
// 7.6 - Pop-up Cookie

.popupCookie_component{
	.layer_shaded{
		position: fixed;
		width: 100vw;
		height: 100vh;
		left: 0;
		top: 0;
		background: rgba(0,0,0,0.8);

	}
	.container{
		position: fixed;
		height: 95vh;
		width: 95vw;
		background: $whitelighter;
		z-index: 99;
		box-shadow: 0px 0px 60px 2px rgba(0,0,0,0.3);
		top: 2.5vh;
		left: 2.5vw;
		overflow-y: scroll;
		.close_icon {
			position: fixed;
			z-index: 9;
			right: 70px;
			background: white;
			padding: 12px;
			border-radius: 100px;
			box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.5);
			transition: 0.2s ease-out;
			top: 30px;
			cursor: pointer;
			transform: scale(0.8);
			transform-origin: 50% 50%;
			&:hover{
				transition: 0.2s ease-out;
				box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.5);
			}
		}
		.title_content{
			color: $greydarker;
			position: relative;
			z-index: 2;
			@include setfont(700, 36px, 50px);
			@include media-min-max("phone", "phonemax"){
				padding: 5px;
			}
			@include media-min-max("tablet", "tabletmax"){
				padding: $spacefixsmall;
			}
			@include media-min("laptop"){
				padding: $spacefixsmall $spacefixlarge;
			}
		}
		.btn-classic{
			@include setfont(600, 16px, 24px);
			display: inline-flex;
			@include media-min-max("phone", "phonemax"){
				margin: 5px;
			}
			@include media-min-max("tablet", "tabletmax"){
				margin: 0 $spacefixsmall;
			}
			@include media-min("laptop"){
				margin: $halfspacefixsmall $spacefixlarge;
			}
		}
		.content{
			width: 100%;
			height: 100%;			
			@include setfont(400, 16px, 20px);
			color: $black;
			@include media-min-max("phone", "phonemax"){
				padding: 5px;
			}
			@include media-min-max("tablet", "tabletmax"){
				padding: $spacefixsmall;
			}
			@include media-min("laptop"){
				padding: $spacefixsmall $spacefixlarge;
				padding-top: 0px;
			}
			h1:first-child{
				margin-top:0;
			}
			h1, h2, h3, h4, h5, h6{
				margin-bottom: $spacefixsmall;
				margin-top: $spacefixsmall*2;
			}
			p:last-child{
				padding-bottom: $spacefixsmall;
			}
		}
	}
}

// ==================================================
// 7.7 Tarteaux citron plugin


#tarteaucitronRoot{
	bottom: 0;
	top: auto;
	#tarteaucitronAlertBig{
		top: auto;
		bottom: 2vh;
		background: $whitedarker;
		width: calc(96vw - 60px);
		left: 2vw;
		padding: $spacefixsmall;
		text-align: center;
		box-shadow: 0px 10px 20px -3px rgba(0,0,0,0.5);
		.accept_cookie_loader{
			position: absolute;
			height: 10px;
			background: $redlighter;
			top: 0;
			left: 0;
		}
		#tarteaucitronDisclaimerAlert{
			width: 100%;
			padding-bottom: $halfspacefixsmall;
			@include setfont(700, 16px, 20px);
			color: $greydarker;
			display: block;
			text-align: center;
			strong{
				@include setfont(700, 20px, 25px);
				color: $greydarker;
				&::first-letter{text-transform: uppercase;}
			}
		}
		#tarteaucitronPersonalize{
			padding: $halfspacefixsmall;
			@include setfont(700, 16px, 20px);
			border-radius: 100px;
		}
		#tarteaucitronCloseAlert{
			color: $reddarker;
			background: transparent;
			padding: $halfspacefixsmall;
			@include setfont(700, 16px, 20px);
		}
	}
	#tarteaucitronPercentage{
		display: none !important;
	}
	#tarteaucitronAlertSmall{
		display: none !important;
	}

}

// ==================================================
// 7.8 - Popup component

.popup_component{
	position: fixed;
	z-index: 99;
	width: 94vw;
	max-width: 450px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 100px;
	margin-left: calc(50vw - 225px);
	transform: translateY(-100px) scale(0);
	.message{
		@include setfont(500, 16px, 24px);
		display: flex;
		align-items: center;
		&>span{
			padding-left: 15px;
		}
	}
	&.success{
		box-shadow: 0px 15px 30px -10px rgba(171, 255, 167, 0.7);
		background: #ABFFA7;
	}
	&.error{
		background: rgb(204, 130, 119);
		box-shadow: 0px 15px 30px -10px rgba(204, 130, 119, 0.7);
	}
	
}



// ==================================================
// 8 - ANIMATIONS
// ==================================================

.full-page, .slider{
	@include media-min("laptop"){
		// default animation
		&.active_anim_section{
			.title,
			.bordered-bott,
			li{
				position: relative;
				opacity: 1;
				@include easetransition;
				transition-delay: 0.3s;
				@include translateY(0px, 50%);
			}
			.subtitle{
				position: relative;
				opacity: 1;
				@include easetransition;
				transition-delay: 0.3s;
				@include translateY(0px, 50%);
			}
			.text,
			.field-form,
			.btn-classic{
				position: relative;
				opacity: 1;
				@include easetransition;
				transition-delay: 0.3s;
				@include translateY(0px, 50%);
			}			
		}
		&.not_active_anim_section{
			.title,
			.bordered-bott,
			li{
				position: relative;
				opacity: 0;
				@include easetransition;
				transition-delay: 0.2s;
				@include translateY(-30px, 50%);
			}
			.subtitle,
			.banner_line{
				position: relative;
				opacity: 0;
				@include easetransition;
				transition-delay: 0.15s;
				@include translateY(-30px, 50%);
			}
			.text,
			.field-form,
			.btn-classic{
				position: relative;
				opacity: 0;
				@include easetransition;
				transition-delay: 0.15s;
				@include translateY(-30px, 50%);
			}
		}
		// specific animations contact
		&#contact_us{
			&.active_anim_section{
				.red_block{
					@include translateX(0%, 100%);
					@include easetransition;
					.col{
						opacity: 1;
						@include easetransition;
						@include translateX(0px, 0%);
						transition-delay: 0.3s;
					}
				}
				.contact_map{
					@include translateX(0%, 100%);
					@include easetransition;
				}
			}
			&.not_active_anim_section{
				.red_block{
					@include translateX(-100%, 100%);
					@include easetransition;
					.col{
						opacity: 0;
						@include easetransition;
						@include translateX(-20px, 0%);
						transition-delay: 0.3s;
					}
				}
				.contact_map{
					@include translateX(100%, 100%);
					@include easetransition;
				}
			}
		}
		&#who_we_are{
			&.active_anim_section{
				.banner{
					@include easetransition;
					@include scaleY(1, 0%);
					.title{
						opacity: 1;
						@include easetransition;
						transition-delay: 0.5s;
						@include translateY(0px, 0%);
					}
					.banner_line{
						opacity: 1;
						@include easetransition;
						transition-delay: 0.5s;
						@include translateY(0px, 0%);
					}
				}
			}
			&.not_active_anim_section{
				.banner{
					@include easetransition;
					@include scaleY(0, 0%);
					.title{
						opacity: 0;
						@include easetransition;
						transition-delay: 0.5s;
						@include translateY(-30px, 0%);
					}
					.banner_line{
						opacity: 0;
						@include easetransition;
						transition-delay: 0.5s;
						@include translateY(-30px, 0%);
					}
				}

			}
		}
	}
}

// IMPORTS STYLES FOR ADDITIONNAL PAGES
@import './classicpage.scss'