html, body{
	margin: 0;
	padding: 0;
	overflow: hidden;
	scrollbar-width: thin;
	scrollbar-color: dark;
	@include media-min("phone"){
		overflow-x: hidden;
		overflow-y: auto;
	}
	@include media-min("laptop"){
		overflow: hidden;
	}
}
*{
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p{
	margin: 0;
	padding: 0;
}

a{
	text-transform: none;
}
ul{
	margin: 0; padding: 0; list-style: none;
	li{margin: 0; padding: 0; list-style: none;}
}

img{
	width: 100%;
}

.full-page{
	width: 100vw;
	height: 100vh;
}

