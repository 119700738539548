// ==================================================
// FUNCTIONNAL MIXINS
// ==================================================
$breakpoints: (
    "phone": 250px,
    "phonemax": 767px,
	"tablet": 768px,
	"tabletmax": 1023px,
	"laptop": 1024px,
	"laptopmax": 1279px,
    "desktop": 1280px,
);
@mixin media-min($_key){
    @media screen and (min-width: map-get($breakpoints, $_key)) {
        @content;
    }
}
@mixin media-max($_key){
    @media screen and (max-width: map-get($breakpoints, $_key)) {
        @content;
    }
}
@mixin media-min-max($key1, $key2){
    @media screen and (min-width: map-get($breakpoints, $key1)) and (max-width: map-get($breakpoints, $key2)){
        @content;
    }
}

@mixin easetransition(){
	transition: 0.5s cubic-bezier(.31,.9,.59,1.01);
}




@mixin setfont($fontWeight, $fontSize, $lineHeight){
	font-family: 'Lato', sans-serif ;
	font-weight: $fontWeight;
	font-size: $fontSize;
	line-height: $lineHeight;
}
@mixin setfontB($fontWeight, $fontSize, $lineHeight){
	font-family: 'Bauhaus', sans-serif ;
	font-weight: $fontWeight;
	font-size: $fontSize;
	line-height: $lineHeight;
}


// ==================================================
// TRANSFORMS TOOLS
// ==================================================


@mixin scale($value, $originX, $originY) {
	transform: scale($value);
	transform-origin: $originX $originY;
}
@mixin scale($value, $originX, $originY) {
	transform: scale($value);
	transform-origin: $originX $originY;
}
@mixin scaleY($value, $originY) {
	transform: scaleY($value);
	transform-origin: 100% $originY;
}
@mixin scaleX($value, $originX) {
	transform: scaleX($value);
	transform-origin: 100% $originX ;
}
@mixin scaleAndTranslateY($value, $value2, $originX, $originY) {
	transform: scale($value) translateY($value2);
	transform-origin: $originX $originY;
}
@mixin scaleAndTranslateY($value, $value2, $originX, $originY) {
	transform: scale($value) translateY($value2);
	transform-origin: $originX $originY;
}

@mixin translateX($value, $originX, $originY) {
	transform: translateX($value);
	transform-origin: $originX $originY;
}
@mixin translateY($value, $originX, $originY) {
	transform: translateY($value);
	transform-origin: $originX $originY;
}

@mixin translateX($value, $originX) {
	transform: translateX($value);
	transform-origin: 50%  $originX;
}
@mixin translateY($value, $originY) {
	transform: translateY($value);
	transform-origin: $originY 50%;
}

// ==================================================
// ANIMATIONS TOOLS
// ==================================================

// @keyframes animSwipper {
// 	0%{
// 		pointer-events: auto;
// 		opacity: 1;
// 	}
// 	100%{
// 		pointer-events: none;
// 		opacity: 0;
// 	}
// }

// @keyframes animSwipperPicto{
// 	0%{
// 		transform: rotate(-10deg) translateX(-20px);
// 	}
// 	25%{
// 		transform: rotate(10deg) translateX(0px);
// 	}
// 	50%{
// 		transform: rotate(-10deg) translateX(-20px);
// 	}
// 	75%{
// 		transform: rotate(10deg) translateX(0px);
// 	}
// 	100%{
// 		transform: rotate(-10deg) translateX(-20px);
// 	}
// }