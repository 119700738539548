.classic_page{
	.header{
		@include media-min("phone"){
			margin-top: $spacefixsmall;
			margin-bottom: $spacefixsmall;
		}
		@include media-min("tablet"){
			margin-top: $spacefixlarge;
			margin-bottom: $spacefixsmall;
		}
		img{
			object-fit: cover;
		}
	}
	.text-container{
		img{
			margin: $spacefixsmall 0;
		}
		h1, h2, h3, h4, h5, h6{
			margin-top: $spacefixlarge / 2;
			margin-bottom: $spacefixsmall;
		}
	}
}