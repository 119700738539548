@font-face {
	font-family: 'Lato';
	src: 	url('/font/Lato-Black.ttf'), 
			url('/font/Lato-Black.ttf');
	font-weight: 900;
}
@font-face {
	font-family: 'Lato';
	src: 	url('/font/Lato-Bold.ttf'), 
			url('/font/Lato-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: 'Lato';
	src: 	url('/font/Lato-Regular.ttf'), 
			url('/font/Lato-Regular.ttf');
	font-weight: 400;
}
@font-face {
	font-family: 'Lato';
	src: 	url('/font/Lato-Heavy.ttf'), 
			url('/font/Lato-Heavy.ttf');
	font-weight: 600;
}
@font-face {
	font-family: 'Bauhaus';
	src: 	url('/font/BAUHAUSM.ttf'), 
			url('/font/BAUHAUSM.ttf');
	font-weight: 600;
}