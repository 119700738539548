// ==================================================
// COLORS
// ==================================================
$whitelighter: #FFFFFF;
$whitedarker: #f1f1f1;
$greylighter: #cecece;
$greydarker: #5a5a5a;
$black: #000000;

$reddarker: #af0009;
$redlighter: #BF262E;

$bluedarkest: #323943;
$bluedarker: #477695;
$bluelighter: #518EB6;


// ==================================================
// SPACES
// ==================================================

$halfspacefixsmall: 15px;
$spacefixsmall: 30px;
$spacefixlarge: 100px;

$pictowidth: 30px;